import React, { Component } from 'react'
import LoginForm from './LoginForm'
import { connect } from 'react-redux'
import Fade from '../../helpers/fade'
import styler from '../../helpers/styler'
import IVWPixel from '../../helpers/ivw'
import Head from '../Layout/Head'
import { client } from '../../helpers/config'

/**
 * Login component
 *
 * @class Login
 * @author Alexander Jungwirth
 */
class Login extends Component {

    state = {
        user: '',
        password: '',
        remember: false,
        bee: '',
        loading: false,
        message: {
            active: false
        }
    }

    componentDidMount () {
        IVWPixel.pageLoad()
    }

    /**
     * render method
     *
     * @returns {*}
     */
    render () {
        return (
            <Fade>
                <Head title="Login"/>
                <div className={styler(['uk-container', 'uk-padding'])}>
                    <div className={styler(['divider'])}>
                        <h2>Login</h2>
                    </div>
                    {this.props.user.hasOwnProperty('id') && this.props.user.id !== null ? (
                        <Fade>
                            <div className={styler(['uk-text-center', 'uk-margin-medium-top', 'uk-margin-medium-bottom'])}>
                                <p>Hallo {this.props.user.salutation} {this.props.user.firstname} {this.props.user.lastname}, Sie haben sich erfolgreich eingeloggt.</p>
                                <p>Sie können jetzt unbegrenzte Kondolenzeinträge verfassen, diese verändern und löschen, und Kondolenzbücher abonnieren.</p>
                            </div>
                        </Fade>
                    ) : (
                        <div>
                            <p>Um sich auf dem Trauerportal von <span className={styler(['uk-text-bold'])}>{client === 'pnp' ? 'Passauer Neue Presse' : 'DONAUKURIER'}</span> einzuloggen, benötigen Sie
                                ein aktives Benutzerkonto auf <span className={styler(['uk-text-bold'])}>{client === 'pnp' ? 'pnp.de' : 'donaukurier.de'}</span>. Wenn Sie noch kein entsprechendes Konto haben, können Sie sich <a href="https://id.donaukurier.de/registrierung/" target="_blank" title="Registrierung" rel="noopener noreferrer">hier</a> registrieren. Dieses Konto können Sie dann für alle unsere Portale nutzen.</p>
                            <LoginForm visible={true} {...this.props} />
                        </div>
                    )
                    }
                </div>
            </Fade>
        )
    }
}

function mapStateToProps (state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(Login)

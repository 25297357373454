export const env = 'prod' // use dev or prod

export const client = 'dk'
export const portalName = (client === 'pnp') ? 'Passauer Neue Presse' : 'DONAUKURIER'
export let portalUrl = 'https://www.donaukurier.de'

export const appName = (client === 'pnp') ? 'PNP Trauerportal' : 'DONAUKURIER Trauerportal'
export let baseUrl = '/'
export let api_url = ''
export let cdn = ''
export let imgWidth = 0


if (env === 'prod') {
  const url = '/'
  api_url = url + 'api/public/v1/'
  switch (client) {
    case 'pnp':
      cdn = 'https://cdntrauer.pnp.de/api/public/v1/image?image='
      baseUrl = 'https://trauer.pnp.de/'
      portalUrl = 'https://www.pnp.de/'
      break

    case 'dk':
      cdn = 'https://cdntrauer.donaukurier.de/api/public/v1/image?image='
      baseUrl = 'https://trauer.donaukurier.de/'
      portalUrl = 'https://www.donaukurier.de/'
      break

    default:
      cdn = api_url + 'image?image='
      baseUrl = 'https://trauer.donaukurier.de/'
      portalUrl = 'https://www.donaukurier.de/'
      break
  }

  imgWidth = 800
} else if (env === 'dev') {
  const url = 'http://127.0.0.1:8000'
  api_url = url + '/v1/'
  cdn = api_url + 'image?image='
  imgWidth = 800
}

